import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import FormRedirectionWrapper from 'components/FormRedirectionWrapper';
import Layout from 'components/Layout';
import AboutYourCoverForm from 'forms/AboutYourCoverForm';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useDriverDetails } from 'state/formData/driverDetails';
import { CsHero, CsMeta } from 'types/contentStack';

const STEP = 3;

type AboutYourCoverProps = {
  data: {
    csAboutYourCover: {
      meta: CsMeta;
      hero: CsHero;
      next_button_text: string;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csAboutYourCover {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
    }
  }
`;

const AboutYourCover: React.FC<AboutYourCoverProps> = ({
  data: {
    csAboutYourCover: {
      meta,
      hero: { heading, subheading },
      next_button_text,
    },
  },
  location,
}) => {
  const [driverDetails] = useDriverDetails();
  usePageTracking(meta.meta_title, !!driverDetails.firstName);

  useEffect(() => trackCheckoutEvent(STEP, null, 'Quote and Buy'), []);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <FormRedirectionWrapper location={location}>
        <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
          <HeroPlain heading={heading} subhead={subheading || undefined} />
          <AboutYourCoverForm
            moveBack={() => navigatePreservingQuote(quoteAndBuyRoutes.aboutYou)}
            moveNextText={next_button_text}
            moveNext={() => navigatePreservingQuote(quoteAndBuyRoutes.additionalDrivers)}
          />
        </Layout>
      </FormRedirectionWrapper>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYourCover;
